<template>
  <div class="d-flex px-5" :class="{
    'flex-column': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
    'flex-wrap': !this.$vuetify.breakpoint.md && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs,
  }">
    <div style="width:100%; height: 8vh"
      class="page-title d-flex align-center primary--text font-weight-medium px-4 pb-4 pt-8">
      <span>Dashboard</span>
    </div>

    <!-- Sopra -->

    <div style="width: 50%; height:45vh" class="pa-2 pt-4" :class="{
      'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
    }">
      <v-card class="dashboard-card pa-2" color="rgba(255, 255, 255, 0.5)">
        <div class="card-title" style="height:9%">
          Prodotti più venduti
        </div>
        <GroupBasedChart height="95%" width="100%" type="bar" id="chart" transparent :colors="topSellingColors"
          :point-colors="topSellingBorderColors" :getYValue="(object) => {
              return object[`BillItems.quantity`];
            }
            " :getXValue="(object) => {
      return object[`Items.description`];
    }
    " :tooltip-label-modifier="(tooltipLabel) => {
      return 'Quantità: ' + tooltipLabel.yLabel;
    }
    " :x-axes-modifier="xAxesTopSelling" :data="[topSellingData]" :loading="topSellingLoading"></GroupBasedChart>
      </v-card>
    </div>
    <div 
      class="d-flex flex-wrap" 
      :style="{
        'width': this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg ? '50%' : '100%'
      }"
    >
      <div 
        :style="{
          'width': this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg ? '50%' : '100%',
          'height': this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg ? '45vh' : '60vh'
        }" 
        class="pa-2 pt-4" 
        :class="{
          'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
        }"
      >
        <a href="https://luluu-chat.vercel.app/" target="_blank">
          <v-card class="dashboard-card d-flex justify-center" style="overflow:hidden;">
            <video autoplay muted loop playsinline id="main-video" style="height:100%;">
              <source src="../assets/LULUU_video.mp4" type="video/mp4">
            </video>
          </v-card>
        </a>
      </div>
      <div 
        :style="{
          'width': this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg  ? '50%' : '100%',
          'height': this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg ? '45vh' : '60vh'
        }" 
        class="pa-2 pt-4" 
        :class="{
          'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
        }"
      >
        <v-card class="dashboard-card d-flex flex-column justify-space-between pa-4">
          <div class="card-title" style="height:12%">
            Messaggi {{ smsCounter }}
          </div>
          <v-btn color="white" dark class="send-button font-weight-medium" :disabled="isLicenseBlocked || isOnlyWheelOfFortune" @click="() => {
            if (!!this.automaticBirthdayMessage) {
              this.popUp = true
              this.popUpText = 'L\'invio dei messaggi avviene automaticamente alle ore 9am'
            }
            else {
              this.customersSendMessage = true
              this.customersAppointmentMessage = false
              this.smsCustomerKey = 'id'
              this.smsCustomerFilterTypes = [
                { type: 'string', operator: 'equal', field: 'cellphone', name: 'Cellulare', label: 'Cellulare', color: '', value: undefined, icon: 'mdi-cellphone' },
                { type: 'boolean', operator: 'equal', field: 'sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: '', value: false, icon: 'mdi-whatsapp' },
              ]
              this.smsCustomerEndTitle = ' clienti nati oggi'
              this.smsCustomersFetchType = this.sendBirthdayMessages
            }
          }">
            <v-row>
              <v-col cols="3" class="d-flex justify-center">
                <v-icon large>mdi-cake-variant</v-icon>
              </v-col>
              <v-col cols="9" class="text-justify">
                Invia Messaggi <br /> Compleanno
              </v-col>
            </v-row>
          </v-btn>
          <v-btn color="white" dark class="send-button font-weight-medium" :disabled="isLicenseBlocked || isOnlyWheelOfFortune" @click="() => {
            this.customersSendMessage = false
            this.customersAppointmentMessage = true
            this.smsCustomerKey = 'customerId'
            this.smsCustomerFilterTypes = [
              { type: 'string', operator: 'equal', field: 'customer.cellphone', name: 'Cellulare', label: 'Cellulare', color: '', value: undefined, icon: 'mdi-cellphone' },
              { type: 'boolean', operator: 'equal', field: 'customer.sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: '', value: false, icon: 'mdi-whatsapp' },
            ]
            this.smsCustomerEndTitle = ' clienti con appuntamento domani'
            this.smsCustomersFetchType = this.sendAppointmentMessages
          }">
            <v-row>
              <v-col cols="3" class="d-flex justify-center">
                <v-icon large>mdi-calendar-check</v-icon>
              </v-col>
              <v-col cols="9" class="text-justify">
                Invia Messaggi <br /> Appuntamenti
              </v-col>
            </v-row>
          </v-btn>
          <v-btn color="white" dark class="send-button font-weight-medium" :disabled="isLicenseBlocked || isOnlyWheelOfFortune" @click="() => {
            this.customersSendMessage = true
            this.smsCustomerKey = 'eventGroupId'
            this.smsCustomerFilterTypes = [
              { type: 'string', operator: 'equal', field: 'customer.cellphone', name: 'Cellulare', label: 'Cellulare', color: '', value: undefined, icon: 'mdi-cellphone' },
              { type: 'boolean', operator: 'equal', field: 'customer.sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: '', value: false, icon: 'mdi-whatsapp' },
            ]
            this.smsCustomerEndTitle = ' clienti con appuntamento oggi'
            this.smsCustomersFetchType = this.sendMarketingMessages
          }">
            <v-row>
              <v-col cols="3" class="d-flex justify-center">
                <v-icon large>mdi-ticket-percent</v-icon>
              </v-col>
              <v-col cols="9" class="text-justify">
                Invia Messaggi <br /> Marketing
              </v-col>
            </v-row>
          </v-btn>
          <v-btn color="white" dark class="send-button font-weight-medium" :disabled="isLicenseBlocked || isOnlyWheelOfFortune" @click="() => { this.sendSmsDialog = true }">
            <v-row>
              <v-col cols="3" class="d-flex justify-center">
                <v-icon large>mdi-account</v-icon>
              </v-col>
              <v-col cols="9" class="text-justify">
                Invia Messaggio <br /> Cliente
              </v-col>
            </v-row>
          </v-btn>
        </v-card>
      </div>
    </div>

    <!-- Sotto -->

    <div 
      :style="{
        'width': this.$vuetify.breakpoint.lg ? '28%': '32%', 
        'height':'45vh'
      }" 
      class="pa-2" 
      :class="{
        'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
      }"
    >
      <v-card class="dashboard-card pa-2" color="rgba(255, 255, 255, 0.5)" style="width:100%; overflow-y: auto">
        <div class="card-title" style="height:9%">
          Fasce Orarie Trimestrali
        </div>
        <GroupBasedChart height="91%" width="99%" id="chart" :getYValue="(object) => {
          return Number(object[`count`])
        }" :getXValue="(object) => {
  return object[`day`]
}" :tooltip-label-modifier="(tooltipLabel) => {
  return tooltipLabel.yLabel + ' appuntamenti'
}" :x-axes-modifier="xAxesQuarter" :data="quarterData" :colors="colorSet" :point-colors="pointColorSet"
          :loading="quarterLoading" transparent>
        </GroupBasedChart>
      </v-card>
    </div>
    <div 
      :style="{
        'width': this.$vuetify.breakpoint.lg ? '28%': '23%', 
        'height':'45vh',
      }" 
      class="pa-2" 
      :class="{
        'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      }"
    >
      <v-card class="dashboard-card pa-2 d-flex flex-column justify-space-between" style="width:100%;">
        <div class="d-flex" style="height:15%">
          <span class="card-title">
            Statistiche
          </span>
          <v-spacer></v-spacer>
          <v-btn rounded color="primary"
            style="background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.8), rgb(80, 191, 225,0.8));"
            @click="() => { this.balanceSettings = 0; this.getBalance(); this.recalculateGraph(); }">
            Mese
          </v-btn>
          <v-btn rounded class="ml-2" color="primary"
            style="background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.8), rgb(80, 191, 225,0.8));"
            @click="() => { this.balanceSettings = 1; this.getBalance(); this.recalculateGraph(); }">
            Anno
          </v-btn>
        </div>

        <div style="width:100%; overflow-y: auto; margin-top: 5px">
          <v-card class="pa-1 mt-1" outlined style="width:100%" v-if="!balanceLoading && !!balanceData">
            <div class="d-flex align-center" style="width:100%">
              <div style="width:15%">
                <v-avatar color="rgba(138,119,226,1)" :size="this.$vuetify.breakpoint.lg ? '40' : '50'">
                  <span class="white--text text-caption">{{ !Number(balanceData.paymentTransactions) ?
                    (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : ((Number(balanceData.total) -
                      Number(balanceData.debits)) / Number(balanceData.paymentTransactions)).toLocaleString('it-IT', {
                        style:
                          'currency', currency: 'EUR'
                      }) }}</span>
                </v-avatar>
              </div>
              <div class="text-center" style="width: 70%;">
                FICHE MEDIA
              </div>
            </div>
          </v-card>
          <v-card class="pa-1" outlined style="width:100%" v-if="!balanceLoading && !!balanceData">
            <div class="d-flex align-center" style="width:100%">
              <div style="width:15%">
                <v-avatar color="rgba(99,143,239,1)" :size="this.$vuetify.breakpoint.lg ? '40' : '50'">
                  <span
                    class="white--text text-caption">{{ Number(balanceData.percItemGain).toLocaleString('it-IT') }}%</span>
                </v-avatar>
              </div>
              <div class="text-center" style="width: 70%;">
                % RIVENDITA
              </div>
            </div>
          </v-card>
          <v-card class="pa-1" outlined style="width:100%" v-if="!balanceLoading && !!balanceData">
            <div class="d-flex align-center" style="width:100%">
              <div style="width:15%">
                <v-avatar color="rgba(63,162,241,1)" :size="this.$vuetify.breakpoint.lg ? '40' : '50'">
                  <span
                    class="white--text text-caption">{{ Number(balanceData.percCustomersWithProduct).toLocaleString('it-IT') }}%</span>
                </v-avatar>
              </div>
              <div class="text-center" style="width: 70%;">
                % CLIENTI CHE ACQUISTANO
              </div>
            </div>
          </v-card>
          <v-card class="pa-1" outlined style="width:100%" v-if="!balanceLoading && !!balanceData">
            <div class="d-flex align-center" style="width:100%">
              <div style="width:15%">
                <v-avatar color="rgba(54,178,235,1)" :size="this.$vuetify.breakpoint.lg ? '40' : '50'">
                  <span
                    class="white--text text-caption">{{ Number(balanceData.paymentTransactions).toLocaleString('it-IT') }}</span>
                </v-avatar>
              </div>
              <div class="text-center" style="width: 70%;">
                N° PASSAGGI DI CASSA
              </div>
            </div>
          </v-card>
          <v-card class="pa-1" outlined style="width:100%" v-if="!balanceLoading && !!balanceData">
            <div class="d-flex align-center" style="width:100%">
              <div style="width:15%">
                <v-avatar color="rgba(80,191,225,1)" :size="this.$vuetify.breakpoint.lg ? '40' : '50'">
                  <span class="white--text text-caption">{{ Number(balanceData.nServices).toLocaleString('it-IT') }}</span>
                </v-avatar>
              </div>
              <div class="text-center" style="width: 70%;">
                MEDIA N° SERVIZI
              </div>
            </div>
          </v-card>
          <v-card class="pa-1 ma-5" outlined style="width:90%;height: 90%;" v-if="!balanceLoading && !balanceData">
            <div class="d-flex align-center" style="width:100%; height: 100%;">
              <div class="text-center" style="width: 100%;">
                DATI INSUFFICIENTI
              </div>
            </div>
          </v-card>
        </div>
      </v-card>
    </div>
    <div style="width: 20%; height:45vh" class="pa-2" :class="{
      'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
    }">
      <v-card class="dashboard-card" style="width:100%; overflow-y: auto">
        <CalendarAnalytics 
          :height="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
            ? 'min(35vh, 80vw)'
            : 'min(28vh, 12vw)'" 
          :width="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
            ? 'min(35vh, 80vw)'
            : 'min(28vh, 12vw)'" type="doughnut" lightColor></CalendarAnalytics>
      </v-card>
    </div>
    <div 
      :style="{
        'width': this.$vuetify.breakpoint.lg ? '24%' : '25%',
        'height': '45vh'
      }" 
      class="pa-2" 
      :class="{
        'fullscreen': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs,
      }"
    >
      <v-card class="dashboard-card pa-2" color="rgba(255, 255, 255, 0.5)" flat
        style="">
        <div class="card-title mb-1" style="height:9%">
          Ultime Notizie
        </div>
        <div
          style="
            height: 91%; 
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;
            padding: 5px;
          "
        > 
          <div
            style="height:30%; width:100%;"
            v-for="(news, idx) in dataNews" 
            :key="idx"
          >
            <v-badge 
              style="height:100%; width:100%" 
              overlap 
              content="NEWS" 
              :value="!news.isRead" 
              offset-x="45"
              offset-y="20"
              color="red"
            >
              <v-card 
                style="
                  height: 100%; 
                  width: 100%; 
                  border-radius: 12px; 
                  overflow-y: auto;
                  padding: 5px;
                "
                class="d-flex align-center" hover @click="openNews(news)"
              >
                <v-img 
                  style="max-height: 90%; max-width:35%; border-radius: 12px" 
                  :src="!!news.attributes.Immagine.data ?
                    (
                      !!news.attributes.Immagine.data.attributes.formats.small ?
                        url + news.attributes.Immagine.data.attributes.formats.small.url
                        : url + news.attributes.Immagine.data.attributes.formats.thumbnail.url
                    )
                    : undefined"
                ></v-img>
                <div class="d-flex align-start flex-column pt-2" style="height:100%;width:95%">
                  <v-card-text style="width:100%; height: 60%;" class="pa-0 pl-2">
                    <div class="text-subtitle-1 font-weight-bold" style="overflow: hidden; height: 100%;">
                      {{ news.attributes.Titolo }}
                    </div>
                  </v-card-text>
                  <div class="d-flex align-center justify-space-between" style="width:100%;">
                    <v-chip color="rgba(80, 191, 225, 0.25)" class="cyan--text py-0 ml-2 mt-1" x-small
                      v-if="!!news.attributes.Categorie.data">
                      {{ news.attributes.Categorie.data.attributes.Nome }}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <div class="text-caption py-0 mt-1">
                      {{ (new Date(news.attributes.publishedAt)).toLocaleDateString('it-IT', {
                        year: 'numeric', month:
                          'numeric', day: 'numeric'
                      }) }}
                    </div>
                  </div>
                </div>
              </v-card>
            </v-badge>
          </div>
        </div>
      </v-card>
    </div>

    <!-- Dialog -->

    <StandardDialog v-if="newsDialog" v-model="newsDialog" :dialog-height="null" dialog-max-width="1600px"
      :title="openedNews.attributes.Titolo">
      <NewsView :opened-news="openedNews">

      </NewsView>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="() => { newsDialog = false; recalculateGraph() }">
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog v-model="sendSmsDialog" :dialog-height="null" title="Invia Messaggio" dialog-max-width="1000px">
      <CustomersAutocomplete class="mt-2" v-model="customerSms" return-object :clearable="false"
        prepend-icon="mdi-account-arrow-right" :append-outer-icon="!!customerSms ? 'mdi-pencil' : undefined">
      </CustomersAutocomplete>

      <SendSmsModel 
        class="mt-4" 
        :customers="[customerSms]"
        :numbers="!!customerSms ? [customerSms.prefixCellphone + customerSms.cellphone] : undefined"
        @input="handleSmsInput">
      </SendSmsModel>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="sendSmsDialog = false">Annulla</v-btn>
      </template>
    </StandardDialog>

    <SendCustomersSms 
      v-model="customersSendMessage" 
      :dialog-height=null 
      :end-title="smsCustomerEndTitle"
      :dialog-max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '90vw' 
        : $vuetify.breakpoint.md ? '75vw' : '45vw'" 
      :customers="messageCustomers" 
      :item-key="smsCustomerKey"
      :filter-types="smsCustomerFilterTypes" 
      @fetch-customers="smsCustomersFetchType"
    ></SendCustomersSms>

    <SendCustomersSms 
      v-model="customersAppointmentMessage" 
      :dialog-height=null 
      :end-title="smsCustomerEndTitle"
      :dialog-max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '90vw' 
        : $vuetify.breakpoint.md ? '75vw' : '45vw'" 
      :customers="messageCustomers" 
      :item-key="smsCustomerKey"
      :filter-types="smsCustomerFilterTypes" 
      :send-sms-model="handleSendAppointmentMessages"
      @fetch-customers="smsCustomersFetchType"
    ></SendCustomersSms>

    <SimplePopUp
      v-model="errorAlert"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '20vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
      :alert-text="errorText"
      color="warning"
      :backgroundImage="undefined"
      icon="mdi-alert-octagon"
    >
      <template v-slot:footer>
        <div
          style="margin-top: 18px; margin-bottom: -12px; 
            display: flex; justify-content: center;
            align-items: center;"
        >
          <v-btn 
            color="error"
            @click="openCustomerSupport"
          >
            Contatta l'assistenza
          </v-btn>
        </div>
      </template>
    </SimplePopUp>

    <SimplePopUp
      v-model="popUp"
      :alert-text="popUpText"
    >
    </SimplePopUp>
  </div>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import CalendarAnalytics from "@/components/calendar/CalendarAnalytics.vue";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import SendPromoSmsModel from "@/components/areas/marketing/SendPromoSmsModel.vue";
import NewsView from '@/components/areas/marketing/News.vue'
import SendSmsModel from '@/components/calendar/SendSmsModel.vue';
import CustomersAutocomplete from '@/components/common/CustomersAutocomplete.vue';
import SendCustomersSms from "@/components/areas/marketing/SendCustomersSms.vue"
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import newsService from '@/services/marketing/news.service';
import balanceService from '@/services/balance/balance.service.js';
import UrlKeeper from '@/services/UrlKeeper.js'
import customerService from "@/services/customers/customers.service.js";
import smsService from '@/services/sms/sms.service.js'
import currentUserService from "@/services/currentUser.service";
import settingService from "@/services/settings/settings.service.js";


export default {
  name: "Dashboard",
  components: {
    StandardDialog,
    CalendarAnalytics,
    GroupBasedChart,
    NewsView,
    SendPromoSmsModel,
    SendSmsModel,
    CustomersAutocomplete,
    SendCustomersSms,
    SimplePopUp
  },
  data: function () {
    let url = UrlKeeper.getUrl('strapi')

    return {
      customersSendMessage: false,
      customersAppointmentMessage: false,
      messageCustomers: [],
      url: url,
      dataNews: [],
      newsDialog: false,
      openedNews: {},
      quartersMapped: {
        0: '1° Trimestre',
        1: '2° Trimestre',
        2: '3° Trimestre',
        3: '4° Trimestre'
      },
      dayMapped: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      xAxesQuarter: [
        {
          id: 'xAxisDay',
          type: 'category',
          gridLines: {
            drawOnChartArea: true
          },
          ticks: {
            callback: function (label) {
              let day = label.split(";")[0];
              let hour = label.split(";")[1];
              if (hour === "0-12") {
                return day
              } else {
                return "";
              }
            }
          }
        },
      ],
      xAxesTopSelling: [{
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          callback: function (label) {
            if (label.length > 11) {
              return label.substring(0, 8) + '...'
            } else {
              return label
            }
          }
        },
      }],
      colorSet: ['rgba(138,119,226,0.7)', 'rgba(99,143,239,0.7)', 'rgba(54,180,241,0.7)', 'rgba(100,191,200,0.7)'],
      pointColorSet: ['rgba(138,119,226,)', 'rgba(99,143,239,1)', 'rgba(54,180,241,1)', 'rgba(100,191,200,1)'],
      topSellingColorSet: ['rgba(138,119,226,0.5)', 'rgba(99,143,239,0.5)', 'rgba(63,162,241,0.5)', 'rgba(54,178,235,0.5)', 'rgba(80,191,225,0.5)'],
      topSellingBorderColorSet: ['rgba(138,119,226,1)', 'rgba(99,143,239,1)', 'rgba(63,162,241,1)', 'rgba(54,178,235,1)', 'rgba(80,191,225,1)'],
      topSellingColors: [],
      topSellingBorderColors: [],
      quarterLoading: false,
      quarterData: [],
      topSellingLoading: false,
      topSellingData: {},
      balanceLoading: false,
      balanceData: {},
      balanceSettings: 0,
      smsCustomersFetchType: () => [],
      smsCustomerKey: "id",
      smsCustomerFilterTypes: [
        { type: 'string', operator: 'equal', field: 'cellphone', name: 'Cellulare', label: 'Cellulare', color: "", value: undefined, icon: "mdi-cellphone" },
        { type: 'boolean', operator: 'equal', field: 'sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: "", value: false, icon: "mdi-whatsapp" },
      ],
      smsModel: undefined,
      smsCounter: 0,
      sendSmsDialog: false,
      customerSms: undefined,
      smsCustomerEndTitle: " clienti",
      errorAlert: false,
      errorText: "",
      currentUser: undefined,
      lastExpirationAlert: undefined,
      lastReceivedNews: undefined,
      popUp: false,
      popUpText: "",
      automaticBirthdayMessage: undefined,
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined,
    }
  },
  props: {
  },
  mounted: async function () {
    const video = document.getElementById('main-video');
    video.addEventListener('ended', () => {
      video.currentTime = 0.05;
      video.play();
    });

    this.balanceSettings = 0;

    this.fetchNews();
    this.fetchSmsCounter();
    this.calculateQuarters()
    this.topSellingCalculate()
    this.getBalance()
    this.calculateExpire()
  },
  methods: {
    async fetchSmsCounter() {
      let response = await smsService.getEsendexBalance()
      this.smsCounter = response.sms.filter((e) => e.type == 'TI').reduce((accumulator, obj) => accumulator + obj.quantity, 0)
    },
    async fetchNews() {
      let rowData = await newsService.getNews(1, 3);

      let readNews = await newsService.getReadNews(rowData.data.map(elem => elem.id))
      for (let i = 0; i < rowData.data.length; i++) {
        let isRead = readNews.find(elem => elem.newsId == rowData.data[i].id)
        rowData.data[i].isRead = !!isRead
      }
      if(this.lastReceivedNews != rowData.data[0].id) {
        this.popUp = true
        this.popUpText = `É arrivata una news per te, leggila ora:\n${rowData.data[0].attributes.Titolo}`
        this.$settings.set('lastReceivedNews', rowData.data[0].id)
      }

      this.dataNews = rowData.data
    },
    async openNews(news) {
      this.openedNews = news
      newsService.createReadNews({ newsId: news.id })

      news.isRead = true
      this.newsDialog = true
    },
    async calculateQuarters() {
      this.quarterLoading = true
      let noData = true

      let today = new Date()

      this.quarterData = []

      for (let i = 0; i < 4; i++) {
        const startMonth = new Date(today)
        startMonth.setMonth(3 * i)
        startMonth.setDate(1)
        startMonth.setHours(0)
        const endMonth = new Date(startMonth)
        endMonth.setMonth(3 + 3 * i)
        endMonth.setDate(0)

        this.items = await balanceService.getTimeSlots({
          from: startMonth,
          to: endMonth
        }).catch(err => {
          this.quarterLoading = false;
        })

        if (this.items.length > 0) {
          noData = false
        }

        let dayCounter = {
          sunday: 0,
          monday: 0,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
        }

        this.quarterData.push({
          label: this.quartersMapped[i],
          xAxisID: 'xAxisHour',
          color: 'black',
          data: [
            { day: 'Lun;0-12', count: 0, value: 'monday' },
            { day: 'Lun;12-14', count: 0, value: 'monday' },
            { day: 'Lun;14-17', count: 0, value: 'monday' },
            { day: 'Lun;17-24', count: 0, value: 'monday' },

            { day: 'Mar;0-12', count: 0, value: 'tuesday' },
            { day: 'Mar;12-14', count: 0, value: 'tuesday' },
            { day: 'Mar;14-17', count: 0, value: 'tuesday' },
            { day: 'Mar;17-24', count: 0, value: 'tuesday' },

            { day: 'Mer;0-12', count: 0, value: 'wednesday' },
            { day: 'Mer;12-14', count: 0, value: 'wednesday' },
            { day: 'Mer;14-17', count: 0, value: 'wednesday' },
            { day: 'Mer;17-24', count: 0, value: 'wednesday' },

            { day: 'Gio;0-12', count: 0, value: 'thursday' },
            { day: 'Gio;12-14', count: 0, value: 'thursday' },
            { day: 'Gio;14-17', count: 0, value: 'thursday' },
            { day: 'Gio;17-24', count: 0, value: 'thursday' },

            { day: 'Ven;0-12', count: 0, value: 'friday' },
            { day: 'Ven;12-14', count: 0, value: 'friday' },
            { day: 'Ven;14-17', count: 0, value: 'friday' },
            { day: 'Ven;17-24', count: 0, value: 'friday' },

            { day: 'Sab;0-12', count: 0, value: 'saturday' },
            { day: 'Sab;12-14', count: 0, value: 'saturday' },
            { day: 'Sab;14-17', count: 0, value: 'saturday' },
            { day: 'Sab;17-24', count: 0, value: 'saturday' },

            { day: 'Dom;0-12', count: 0, value: 'sunday' },
            { day: 'Dom;12-14', count: 0, value: 'sunday' },
            { day: 'Dom;14-17', count: 0, value: 'sunday' },
            { day: 'Dom;17-24', count: 0, value: 'sunday' },
          ]
        })

        let previousDate = new Date()
        this.items.forEach((elem, index) => {
          let dayIdx = new Date(elem['EventGroupsWithTimezones.startdate.day']).getDay()
          let dayName = this.getDayName(dayIdx)

          if (index == 0) {
            dayCounter[Object.keys(dayCounter)[dayIdx]] += 1
          }

          else if (index > 0 && previousDate.getTime() != new Date(elem['EventGroupsWithTimezones.startdate.day']).getTime()) {
            dayCounter[Object.keys(dayCounter)[dayIdx]] += 1
          }

          previousDate = new Date(elem['EventGroupsWithTimezones.startdate.day'])

          let hourSlot = elem['EventGroupsWithTimezones.timeslot']
          let completeDayName = dayName + ';' + hourSlot


          let idx = this.quarterData[i].data.findIndex(el => { return el.day == completeDayName })
          let partialCount = Number(this.quarterData[i].data[idx]["count"])
          this.quarterData[i].data[idx]["count"] = partialCount + Number(elem['EventGroupsWithTimezones.count'])
        })

        this.quarterData[i].data.forEach((elem, index) => {
          this.quarterData[i].data[index]["count"] = Number(Number(elem["count"]) / Number(dayCounter[elem["value"]])).toFixed(0)
        })
      }

      if (noData) {
        this.quarterData = []
      }

      this.quarterLoading = false
    },
    async topSellingCalculate() {
      this.topSellingLoading = true;

      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay.setHours(0);
      firstDay.setMinutes(0);

      var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      lastDay.setHours(0);
      lastDay.setMinutes(0);

      let topSellingItems = await balanceService.getTopSellingItems({
        from: firstDay,
        to: lastDay,
        limit: 10,
      }).catch(err => {
        this.topSellingLoading = false;
      })

      for (let i = topSellingItems.length; i < 10; i++) {
        topSellingItems.push({
          'Items.description': 'Vuoto ' + (i + 1),
          'BillItems.quantity': 0
        })
      }

      let color = [];
      for (let i = 0; i < topSellingItems.length; i++) {
        let idx = Math.floor(i / 2)
        color.push({
          //backgroundColor: this.colorSet[i % this.colorSet.length],
          backgroundColor: this.topSellingColorSet[idx],
          //borderColor: this.pointColorSet[i % this.pointColorSet.length],
          borderColor: this.topSellingBorderColorSet[idx],
        });
      }
      this.topSellingColors = color.map((c) => c.backgroundColor);
      this.topSellingBorderColors = color.map((c) => c.borderColor);

      this.topSellingData = {
        name: "Prodotti più venduti questo mese",
        label: "Prodotti più venduti questo mese",
        data: topSellingItems,
      };

      this.topSellingLoading = false;
    },
    getDayName(day) {
      return this.dayMapped[day]
    },
    async recalculateGraph() {
      this.quarterLoading = true;
      setTimeout(() => {
        this.quarterLoading = false;
      }, 300);
    },
    openExternalPage(link) {
      window.open(link, '_blank');
    },
    async sendBirthdayMessages(filters) {
      if (!!this.birthdayModelSelect && !!this.whatsappNumber) {
        let birthday = new Date()
        birthday.setUTCHours(0)
        birthday.setUTCMinutes(0)
        birthday.setUTCSeconds(0)
        birthday.setUTCMilliseconds(0);

        if (!filters) filters = []

        const result = await customerService.list(1, 10000, [...filters, {
          color: "",
          field: "birthday",
          label: "",
          name: "",
          operator: "custom",
          type: "custom",
          value: birthday
        }])
        this.messageCustomers = result['rows']
      } else {
        this.$delegates.snackbar("Manca il numero del negozio");
        return
      }
    },
    async sendMarketingMessages(filters) {
      this.messageCustomers = await smsService.getCustomersByDate(undefined, filters)
    },
    async sendAppointmentMessages(filters) {
      if (!!this.appointmentModelSelect && !!this.whatsappNumber) {
        let today = new Date()
        let tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)
        tomorrow.setHours(0)
        tomorrow.setMinutes(0)
        tomorrow.setSeconds(0)

        const result = await smsService.confirmSmsList(tomorrow, this.appointmentModelSelect, filters)
        this.messageCustomers = result.map(
          (el) => {
            return {
              ...el,
              id: el.customerId,
              cellphone: el.cellphone.replace(el.prefix.replace('+', ''), '')
            }
          }
        )
      } else {
        this.$delegates.snackbar("Manca il numero del negozio");
        return
      }
    },
    //tenere anche i parametri non utilizzati perchè viene chiamata da un componente che passa tutti i parametri
    async handleSendAppointmentMessages(shopNumber, model, isCustom = false, customMessage, withList, sendAll, customers, filters) {
      let today = new Date()
      let tomorrow = new Date()
      tomorrow.setDate(today.getDate() + 1)
      tomorrow.setHours(0)
      tomorrow.setMinutes(0)
      tomorrow.setSeconds(0) 

      smsService.sendAppointmentMessage(shopNumber, tomorrow, undefined, model.text, customers)
    },
    handleSmsInput() {
      this.sendSmsDialog = false
      this.customerSms = undefined
    },
    getBalance() {
      this.balanceLoading = true;

      let startdate = new Date()
      let enddate = new Date()

      if (this.balanceSettings == 0) {
        startdate.setHours(0)
        startdate.setMinutes(0)
        startdate.setSeconds(0)
        startdate.setMilliseconds(0)
        startdate.setDate(1)

        enddate.setHours(23)
        enddate.setMinutes(59)
        enddate.setSeconds(59)
        enddate.setMilliseconds(999)
        enddate.setMonth(enddate.getMonth() + 1)
        enddate.setDate(0)
      } else if (this.balanceSettings == 1) {
        startdate.setHours(0)
        startdate.setMinutes(0)
        startdate.setSeconds(0)
        startdate.setMilliseconds(0)
        startdate.setDate(1)
        startdate.setMonth(0)

        enddate.setHours(23)
        enddate.setMinutes(59)
        enddate.setSeconds(59)
        enddate.setMilliseconds(999)
      }

      balanceService.homepageBalance({ from: startdate, to: enddate }).then((result) => {
        this.balanceData = result.res
        this.balanceData.debits = result.paymentDebitDetails.total
        this.balanceLoading = false;
      }).catch((err) => {
        this.balanceData = undefined
        this.balanceLoading = false;
      })
    },
    async calculateExpire() {
      let today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)

      let lastExpirationAlert = new Date(this.lastExpirationAlert)
      lastExpirationAlert.setHours(0)
      lastExpirationAlert.setMinutes(0)
      lastExpirationAlert.setSeconds(0)
      lastExpirationAlert.setMilliseconds(0)

      if (lastExpirationAlert.getTime() == today.getTime()) {
        return
      }

      const currentUser = await currentUserService.getUser()
      let expireDate = new Date(currentUser.licenses[0].expirationDate)
      expireDate.setHours(0)
      expireDate.setMinutes(0)
      expireDate.setSeconds(0)
      expireDate.setMilliseconds(0)

      let diffTime = Math.floor((expireDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000))
      if (diffTime < 7) {
        this.errorAlert = true
        this.errorText = `La scadenza per il noleggio del programma Luluu è in scadenza\nGiorni rimasti ${diffTime}\n`
        this.$settings.set('lastExpirationAlert', today)
      }
    },
    async openCustomerSupport() {
      let zohoLink = "https://forms.zohopublic.eu/gildasrl/form/Test/formperma/3QNkgj8rbWzuPrVcIvGmFplFAYqPFJn-MtUdPgFYAbs?"
      
      let nameForTicket = await settingService.get('nameForTicket')
      let lastNameForTicket = await settingService.get('lastNameForTicket')
      let salonName = await settingService.get('salonName')
      let companyBusinessName = await settingService.get('companyBusinessName')
      let companyEmail = await settingService.get('companyEmail')
      let companyPhone = await settingService.get('companyPhone')
      
      zohoLink += "Nome=" + nameForTicket.value + "&Cognome=" + lastNameForTicket.value + 
        "&NomeSalone=" + salonName.value + "&RagioneSociale=" + companyBusinessName.value + "&eMail=" + companyEmail.value + 
        "&Telefono=" + companyPhone.value
      
      window.open(zohoLink, '_blank').focus()
    },
  },
  computed: {
  },
  watch: {
  },
  settings: {
    birthdayModelSelect: {
      bind: "birthdayModelSelect",
    },
    whatsappNumber: {
      bind: "whatsappNumber"
    },
    appointmentModelSelect: {
      bind: "appointmentModelSelect",
    },
    lastExpirationAlert: {
      bind: "lastExpirationAlert"
    },
    lastReceivedNews: {
      bind: "lastReceivedNews"
    },
    automaticBirthdayMessage: {
      bind: 'automaticBirthdayMessage'
    }
  },
  currentUser: {
    bind: 'currentUser'
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
  isOnlyWheelOfFortune: {
    bind: "isOnlyWheelOfFortune"
  },
};
</script>

<style scoped>
.fullscreen {
  width: 100% !important;
  height: 60vh !important;
}

.dashboard-card {
  height: 100%;
  border-radius: 6px;
}

.send-button {
  min-height: 8vh;
  background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  border-radius: 6px;
  transition: all 0.2s;
  transform: scale(1);
}

.send-button:hover {
  min-height: 9vh;
  background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.8), rgb(80, 191, 225, 0.8));
  transition: all 0.2s;
  transform: scale(1.05);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 0.5rem;
}

.page-title {
  font-size: 2.3rem;
  font-weight: 400;
}
</style>